.notes h1 {
	display: block;
	font-size: 2em;
	font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}

.notes h2 {
	display: block;
	font-size: 1.5em;
	font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}

.notes h3 {
	display: block;
	font-size: 1.17em;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}

.notes a {
	color: blue;
	text-decoration: underline;
}

.notes a:visited {
	color: purple;
}

.notes a:hover {
	cursor: pointer;
}

.notes a:active {
	color: red;
}

.notes ul,
.notes ol {
	display: block;
	padding: 0 0 0 40px;
}

.notes ul {
	list-style-type: disc;
	list-style-position: inside;
}

.notes ol {
	list-style-type: decimal;
	list-style-position: inside;
}