.RSPBprogressBar {
  height: 10px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgba(211, 211, 211, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgba(0, 116, 217, 0.8);
  z-index: -1;
}
