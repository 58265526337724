.day-grid div:first-child,
.day-grid div:nth-child(2),
.day-grid div:nth-child(3),
.day-grid div:nth-child(4),
.day-grid div:nth-child(5),
.day-grid div:nth-child(6),
.day-grid div:nth-child(7),
.day-grid div:nth-child(8),
.day-grid div:nth-child(9),
.day-grid div:nth-child(10),
.day-grid div:nth-child(11),
.day-grid div:nth-child(12),
.day-grid div:nth-child(13),
.day-grid div:nth-child(14),
.day-grid div:nth-child(15),
.day-grid div:nth-child(42),
.day-grid div:nth-child(43),
.day-grid div:nth-child(44),
.day-grid div:nth-child(45),
.day-grid div:nth-child(46),
.day-grid div:nth-child(47),
.day-grid div:nth-child(48),
.day-grid div:nth-child(49) {
	background-color: rgb(249 250 251);
}

.whole-day-grid div {
	background-color: rgb(249 250 251);
}
