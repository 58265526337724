.scroll-container {
	height: 100px;
	overflow-y: hidden;
	overflow-x: hidden;
}

.scroll-container:hover {
  overflow: auto;
}

.scroll-container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.scroll-container::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
	background: #888;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.scroll-container::-webkit-scrollbar-button {
	display: none;
}
