@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    font-family: 'Nunito Sans';
  }

  .calendar-icon {
    border-style: none;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
  }

  .calendar-icon .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #F5B715 !important;
  }

  .calendar-icon .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #F5B715;
  }

  .calendar-icon .react-calendar__tile {
    border: none !important;
  }
  
  .calendar-icon .react-calendar__navigation button {
    background: none;
    color: black;
    font-weight: bold;
  }

  .calendar-icon .react-calendar__navigation button:disabled {
    display: none;
  }
  
  .calendar-icon .react-calendar__month-view__weekdays {
    background-color: white;
    color: black
  }
  
  .calendar-icon .react-calendar__month-view__weekdays__weekday {
    padding: 10px;
    font-weight: bold;
    font-family: "Nunito Sans";
    font-size: "10px";
    text-decoration: none !important;
  }

  .calendar-icon .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: transparent
  }
  
  .calendar-icon .react-calendar__month-view__weekdays__weekday > * {
    text-decoration: none !important;
    text-transform: capitalize
  }
  
  .calendar-icon .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }
  
  .calendar-icon .react-calendar__navigation button:disabled {
    display: none;
  }

  .react-calendar .react-calendar__tile {
    border: 0.542px solid #D5D4DF;
  }

  .react-calendar .react-calendar__tile:disabled {
    color: rgb(156 163 175);
    background: none;
  }

  .color-picker .react-colorful {
    width: 100%;
  }

  .PhoneInput {
    border: 1px solid #D3E3F1;
  }

  div.PhoneInput.PhoneInput--focus {
    border: 1px solid #13BAB4;
  }

  .PhoneInput:focus,
  .PhoneInputInput:focus {
    border: 1px solid #13BAB4;
  }

  .PhoneInputInput {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .PhoneInput .PhoneInputCountry {
    background-color: rgba(127, 154, 178, 0.10);
    padding: 0px 4px 0px 12px;
  }

  .PhoneInput .PhoneInputCountrySelectArrow {
    margin-left: 6px;
    margin-right: 6px;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer
  }

  .date-filter::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    width: auto;
  }
  
  .myswiper .swiper-pagination > .swiper-pagination-bullet-active {
    background: #13BAB424;
  }

  .myswiper .swiper-button-prev {
    color: #001E00;
  }
}
